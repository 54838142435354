import request from '@/utils/request'

const tradeProjectRemarkApi = {
  trade_project_remark_list: '/trade/project_remark/',
  trade_project_remark_create: '/trade/project_remark/',
  trade_project_remark_update: '/trade/project_remark/',
  trade_project_remark_delete: '/trade/project_remark/'
}

/**
 * 列表
 */
export function project_remark_list (parameter) {
  return request({
    url: tradeProjectRemarkApi.trade_project_remark_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function project_remark_create (parameter) {
  return request({
    url: tradeProjectRemarkApi.trade_project_remark_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function project_remark_update (parameter, project_remark_id) {
  return request({
    url: tradeProjectRemarkApi.trade_project_remark_update + project_remark_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function project_remark_delete (project_remark_id) {
  return request({
    url: tradeProjectRemarkApi.trade_project_remark_delete + project_remark_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
