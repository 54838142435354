<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 15px"
      :title="this.name"
      sub-title="项目备注"
      @back="() => this.$router.back()">
    </a-page-header>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="true">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="创建人">
              <a-select
                v-if="false"
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.create_by_id"
                placeholder="请输入创建人"
                :default-active-first-option="false"
                @search="this.clientSearch"
                @change="handleClientChange"
              >
                <a-select-option v-for="d in clientList" :key="d.id" :tt="d.nickname">
                  {{ d.nickname }}
                </a-select-option>
              </a-select>
              <a-input v-model="queryParam.create_by" placeholder="创建人名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleAdd">新建</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { project_remark_list, project_remark_create, project_remark_delete } from '@/api/trade_project_remark'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import debounce from 'lodash/debounce'
import { user_list } from '@/api/user'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    this.clientSearch = debounce(this.handleClientSearch, 800)
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      name: '',
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '备注',
          dataIndex: 'content'
        },
        {
          title: '创建人',
          dataIndex: 'create_by'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return project_remark_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      clientList: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.name = this.$route.query.record
    this.queryParam.project_id = this.$route.params.project_id
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      const date = new Date()
      this.mdl = { code: date.getFullYear() + '' + (date.getMonth() + 1) + date.getDay() }
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDelete (record) {
      project_remark_delete(record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, this.$route.params.project_id)
            // 新增
          values.project_id = this.$route.params.project_id
          project_remark_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              if (res.code === 1000) {
                this.$refs.table.refresh()
              }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleClientSearch (value) {
      user_list({ nickname: value }).then((res) => {
        const result = res.data.entries || []
        this.clientList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleClientChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleClientSearch(value)
      }
    }
  }
}
</script>
